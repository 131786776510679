import { useState, useRef, useEffect, useCallback } from 'react';
import classnames from 'classnames';
import { Link, matchPath, useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/header/logo_top.svg';
import { ReactComponent as CloseIcon } from 'assets/images/common/close.svg';
// import mobileGallery from 'assets/images/header/mobile-gallery.svg';
// import mobileMuseum from 'assets/images/header/mobile-museum.svg';
// import mobileMap from 'assets/images/header/mobile-map.svg';
// import SelectLanguage from '../SelectLanguage/SelectLanguage';
import { useAppSelector } from 'shared/hooks/useAppSelector';
import { useOnScreen } from 'shared/hooks/useOnScreen';
import NavigationList from './NavigationList';
import { useDispatch, useSelector } from 'react-redux';
import { selectIsOpen, setIsOpen } from '../../manageStore/common/common.slice';

type THeaderProps = {
  isWightText?: boolean;
  isFixed?: boolean;
  isWhiteLink?: boolean;
};

const Header = ({ isWightText, isFixed, isWhiteLink = false }: THeaderProps) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const isOpen = useSelector(selectIsOpen);

  const isAuth = useAppSelector((state) => state.user.isAuth);
  const userData = useAppSelector((state) => state.user.userData);

  const elementRef = useRef<HTMLImageElement>(null);
  const isOnScreen = useOnScreen(elementRef);

  const mobileNavBtn = useRef<HTMLDivElement>(null);
  const [mobileMenu, setMobileMenu] = useState(false);
  const handleMobileMenu = () => setMobileMenu((prev) => !prev);

  const handelPortal = useCallback(() => {
    dispatch(setIsOpen(true));
    setMobileMenu(false);
  }, [setMobileMenu]);

  useEffect(() => {
    setMobileMenu(false);
  }, [location, isOpen]);

  return (
    <>
      <div ref={elementRef}></div>
      {isFixed && <div className='header-imitation'></div>}
      <header
        className={classnames('header', { fixed: isFixed, white: isWightText && isOnScreen, isWhiteLink: isWhiteLink })}
      >
        <div className='container header__container'>
          <div className='header__inner'>
            <Link className='header__logo' to='/'>
              <img className='logo' src={logo} alt='Туркластер Арктический' />
            </Link>
            <div className={classnames('header__main', { white: isWightText && isOnScreen })}>
              <div className='header__main_container'>
                <NavigationList />
                {/* <SelectLanguage /> */}
                {isAuth ? (
                  <Link className='header__menu-item header__menu-link' to={userData?.type === 'OPERATOR'?'account/operator/stats':'/account/tourist/requests'}>
                    {userData?.login}
                  </Link>
                ) : (
                  <div onClick={handelPortal} className='header__menu-item header__menu-link'>
                    {t('headerMenu.logIn')}
                  </div>
                )}
                {/* todo Удалить style при включении SelectLanguage */}
                <div className='header-mobile__btn' onClick={handleMobileMenu} ref={mobileNavBtn}>
                  <div className='header-mobile__btn__row'></div>
                  <div className='header-mobile__btn__row'></div>
                  <div className='header-mobile__btn__row'></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isOnScreen && (
          <div
            className='scroll-top'
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <svg width='100%' height='100%' viewBox='0 0 24 24' fill='#2b4761' xmlns='http://www.w3.org/2000/svg'>
              <path d='M12 8L4 14.453L5.26151 16L12 10.5645L18.7385 16L20 14.453L12 8Z'></path>
            </svg>
          </div>
          
        )}
        <div className={classnames('header-mobile__nav', { open: mobileMenu })}>
          <div className='header-mobile__nav__cover' onClick={handleMobileMenu}></div>
          <div className='header-mobile__nav__container'>
            <CloseIcon className='close-icon' onClick={handleMobileMenu} />
            <h4 className='header-mobile__nav__head'>меню</h4>
            <NavigationList isMobile />
            {isAuth ? (
              <Link className='header__menu-item header__menu-link' to='/account/tourist/requests'>
                {userData?.login}
              </Link>
            ) : (
              <div onClick={handelPortal} className='header__menu-item header__menu-link'>
                Личный кабинет
              </div>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
