import parse from "html-react-parser";
import {TBlog} from "../../shared/types/types";
import {GalleryBlock} from "./GalleryBlock/GalleryBlock";
import {ListDopLink} from "./ListDopLink/ListDopLink";
import {GuideBlock} from "./GuideBlock/GuideBlock";
import {getUrlImg} from "../../helpers/getUrlImg";
import {ToursBlock} from "./ToursBlock/ToursBlock";
import {RelateBlog} from "./RelateBlog/RelateBlog";
import {TravelGuideItemVideo} from "../../widgets/lib/travelGuideItemMain/TravelGuideItemVideo/TravelGuideItemVideo";
import moment from 'moment'
import 'moment/locale/ru';
import {NextBlog} from "./NextBlog/NextBlog";
import {NavLink} from "react-router-dom";
import {TagEntity} from "../../shared/lib/TagEntity/TagEntity";
import {PlaceOnMap} from "./PlaceOnMap/PlaceOnMap";
import {convertEnumTerritoryToLabel} from "../../helpers/blog/convertTagTeritoryToEnumTerritory";
import {TERRITORY_ENUM} from "../../shared/constants/travelGuide.constants";
import { ShareBlog } from "./ShareBlog/ShareBlog";

type TSingleBlogBodyProps = {
    blog: TBlog | null;
    hasAnyError: boolean;
};
export const SingleBlogBody = ({blog, hasAnyError}: TSingleBlogBodyProps) => {
    const {
        preview = null,
        name = '',
        description = '',
        facts = '',
        photo = null,
        galleryWithAuthor = null,
        tours = [],
        relatedBlogs = [],
        interest = '',
        video = '',
        guidebooks = [],
        createdDate = '',
        isImportant = false,
        author = '',
        nextBlog = null,
        typeTags = [],
        locations= [],
        territoryTag= TERRITORY_ENUM.ALL
    } = blog ? blog : {}
    moment.locale('ru')
    const convertData = moment(createdDate).format('DD MMMM YYYY')
    const prewUrlImg = getUrlImg(preview && preview.path ? preview.path : null);
    const photoUrlImg = getUrlImg(photo && photo.path ? photo.path : null);
    const inlineStyles = {
        backgroundImage: `url(${prewUrlImg})`
    };

    let convertTagTerritory = convertEnumTerritoryToLabel(territoryTag);
    const inlineStylesDopImg = {
        backgroundImage: `url(${photoUrlImg})`
    };

    return !hasAnyError ? (
        <>
            <div className='blogTitle' style={inlineStyles}>
                <div className="whiteTrinagle"></div>
                <div className='container container-blog'>
                    <div className="blogTitle__wrap">
                        <h1 className='blogTitle__title title'>{name}</h1>
                        <div className="blogTitle__dopinfo">
                            {isImportant ? <div className="commonTag">важно</div> : <></>}
                            {convertTagTerritory ? <div className="commonTag">{convertTagTerritory}</div> : <></>}
                            <div className="commonTime">{convertData}</div>
                            <div className='tagList'>
                                {typeTags && typeTags.map((tag)=>(<TagEntity key={tag} className='news-item__type' tag={tag}  />))}
                            </div>
                        </div>
                        <NavLink to={'/blog'} className='blogTitle__backbtn' >
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"
                                 fill="none">
                                <path
                                    d="M14.999 7.96565L1.57288 7.96565M1.57288 7.96565L6.92867 2.60986M1.57288 7.96565L6.92867 13.3214"
                                    stroke="#2B4761" strokeWidth="1.8"/>
                            </svg>
                            Назад
                        </NavLink>
                    </div>
                </div>
            </div>

            <div className='container container-blog '>
                <div className="blogText">
                    <div className='leftBlock'>
                        <h3>Описание</h3>
                        <div className='htmlText'>
                            {parse(description || '', {trim: true})}
                        </div>
                        <GalleryBlock gallery={galleryWithAuthor}/>
                        <h3>Важные факты </h3>
                        <div className='htmlText'>
                            {parse(facts || '', {trim: true})}
                        </div>
                        <div className='wrapSipleImg'>
                            <div className="simpleImgBG" style={inlineStylesDopImg} >

                            </div>
                            {/*<img src={photoUrlImg} alt="" className='simpleImg'/>*/}
                            <div className='dopImgAuthor'>© Автор: {author}</div>
                        </div>
                        <h3>Что посмотреть</h3>
                        <ListDopLink intresting={interest}/>

                    </div>

                    <div className='rightBlock'>
                        {relatedBlogs && relatedBlogs.length >0 ? <RelateBlog blogs={relatedBlogs}/> : <></>}
                        <ShareBlog />
                    </div>
                </div>
                <div id='endBlog'></div>
                {guidebooks && guidebooks.length > 0 ? <GuideBlock travelGuides={guidebooks}/> : <></>}
                {locations && locations.length>0 ? <PlaceOnMap locations={locations} /> : <></> }
                {video ? <TravelGuideItemVideo link={video} isRectangleAfter={true} isRectangleBefore={true}/> : <></>}
                {tours && tours.length > 0 ? <ToursBlock tours={tours}/> : <></>}
            </div>
            {nextBlog && <NextBlog blog={nextBlog}/>}
            <div id='endForNextBlog'></div>
        </>
    ) : (
        <>
            <h1 className='single-news__error'>Ууупс! Кажется, произошла ошибка!</h1>
        </>
    );
};
